import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { NavLink, useParams, useHistory } from 'react-router-dom'
import PolicyStats from './policyStats';
import QuotationStats from './quotationStats';
import VideoTutorials from '../global/videoTutorials';
import PolicyReport from './policyReport';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { getAuthState } from '../../assets/scripts/login-util';

const policy = (props) => {
    const user = getAuthState('user')

    const [showVideoTutorials,  setShowVideoTutorials]  = useState(false);
    const param = useParams();

    useEffect(() => {
        props.title("Policy Status", "menuPolicy")
    }, []);

    useEffect(() => {
        $('[data-toggle="kt-popover"]').popover();
    });

    const history = useHistory();

    const navigateTo = (path) => {
        history.push(path);
    };

    const closeDrawerHandler = () => {      
        setTimeout(() => {
            setShowVideoTutorials(false);
        }, 180);
    };

    return (
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
            <div className="kt-portlet kt-portlet--mobile" >
                <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                    <div className="kt-portlet__head-label">
                        <nav className="file-nav navbar navbar-top" >
                            <NavLink activeClassName="top-nav-active" to="/policy-stats/all" >All Policies</NavLink>
                            <NavLink activeClassName="top-nav-active" to="/policy-stats/quotations" >Quotations</NavLink>
                            <NavLink activeClassName="top-nav-active" to="/policy-stats/expired" >Lapsed</NavLink>
                            {user.categoryId !== 3 && <NavLink activeClassName="top-nav-active" to="/policy-stats/report" >Report</NavLink>}
                        </nav>
                    </div>                       

                    <div className="kt-portlet__head-toolbar">
                        <div className="kt-portlet__head-wrapper">
                            <div className="kt-portlet__head-actions">
                                <button 
                                    className="btn btn-label-brand btn-icon-sm margin-r10 padding-8" 
                                    onClick={() => setShowVideoTutorials(true)}
                                    data-toggle="kt-popover"
                                    data-trigger="hover"
                                    data-placement="left"
                                    data-content="Video Tutorials">
                                    <i className="socicon-youtube la-2x padding-l5"></i>
                                </button>
                                                                  
                                {param.typeOfPolicy === 'quotations' &&
                                (
                                    <button className="btn btn-brand btn-icon-sm text-white" style={{display: 'inline'}} onClick={() => props.onOpenModal('quotation', true, 'quotation')}>
                                        <i className="la la-plus"></i>
                                        New Quotation
                                    </button>
                                    
                                )}
                                {param.typeOfPolicy === 'report' &&
                                (
                                    <ReactHTMLTableToExcel
                                        id="policyCommissionReport"
                                        className="download-table-xls-button btn btn-label-brand mr-3"
                                        table="policy-commission-table"
                                        filename="policy-commission-report"
                                        sheet="policy-commission-report"
                                        buttonText="Export To Excel"
                                    />
                                    
                                )}
                                {param.typeOfPolicy === 'all' && (
                                    <React.Fragment>
                                        <button className="btn btn-label-brand btn-icon-sm" onClick={() => navigateTo('/batch-upload/policies/upload')}>
                                            <i className="la la-upload"></i>
                                            Batch Upload
                                        </button>
                                        <button className="btn btn-brand btn-icon-sm text-white margin-l10" style={{display: 'inline'}}  onClick={() => props.onOpenModal('policy', true)}>
                                            <i className="la la-plus"></i>
                                            New Policy
                                        </button>
                                    </React.Fragment>
                                )}                     
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="kt-portlet__body kt-portlet__body--fit">
                    {param.typeOfPolicy === 'quotations' ? (
                        <QuotationStats
                            update={props.updateQuotation}
                            onOpenModal={props.onOpenModal}  
                        />
                    ) : param.typeOfPolicy === 'report' ? (
                        <PolicyReport
                        />
                    ) : (
                        <PolicyStats
                            type={param.typeOfPolicy}
                            update={props.update}
                            updatePolicyQuotation={props.updatePolicyQuotation}
                            onOpenModal={props.onOpenModal}
                            policyData={props.policyData}
                        />
                    )}
                    {showVideoTutorials && (
                        <VideoTutorials
                            onCloseDrawer={closeDrawerHandler}
                            show={showVideoTutorials}
                            playlistId={param.typeOfPolicy === 'quotations' ? 'PLMOvcHEgzkJtK2mwh8La9aL49056wRv3X' : 'PLMOvcHEgzkJu53XRn3qGwn6DHNx7nOlQR'}
                            title="Video Tutorials">
                        </VideoTutorials>
                    )}
                </div>
            </div>
        </div>
    )

};

export default policy;