import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';
import '../../assets/styles/dark.css';
import BannerLogoLight from '../../assets/images/InsureBox-banner.png';
import LogoLight from '../../assets/images/logo-light.png';
import { getAuthState } from '../../assets/scripts/login-util';

const sideMenu = (props) => {
  
  const user = getAuthState('user')

  useEffect(() => {
    $('.kt-menu__item').removeClass('kt-menu__item--active');
    $('#' + props.menuID).addClass('kt-menu__item--active');

  });

  const toggleTitle = () => {
    if ($('body').hasClass('kt-aside--minimize')) {
      $('#menuDashboard .kt-menu__link-icon .kt-svg-icon').attr(
        'data-original-title',
        'Dashboard'
      );
      $('#menuPolicy .kt-menu__link-icon .kt-svg-icon').attr(
        'data-original-title',
        'Policy Stats'
      );
      $('#menuClients .kt-menu__link-icon .kt-svg-icon').attr(
        'data-original-title',
        'Clients'
      );
      $('#menuTeam .kt-menu__link-icon .kt-svg-icon').attr(
        'data-original-title',
        'Team'
      );
      $('#menuSubAgency .kt-menu__link-icon .kt-svg-icon').attr(
        'data-original-title',
        'Sub Agency'
      );
      $('#menuTask .kt-menu__link-icon .kt-svg-icon').attr(
        'data-original-title',
        'Tasks'
      );
      $('#menuFiles .kt-menu__link-icon .kt-svg-icon').attr(
        'data-original-title',
        'Files'
      );
      $('#menuReports .kt-menu__link-icon .kt-svg-icon').attr(
        'data-original-title',
        'Reports'
      );
      $('#menuSupport .kt-menu__link-icon .kt-svg-icon').attr(
        'data-original-title',
        'Support'
      );
      $('.kt-aside__brand-tools').addClass('aside-brand-tools');
      $('#asideBrandLogo').attr('src', LogoLight);
      $('#asideBrandLogo').attr('height', '30');
    } else {
      $('.kt-menu__link-icon .kt-svg-icon').removeAttr('data-original-title');
      $('.kt-aside__brand-tools').removeClass('aside-brand-tools');
      $('#asideBrandLogo').attr('src', BannerLogoLight);
      $('#asideBrandLogo').attr('height', '22');
    }
  };

  // const logout = () => {
  //     setAuthState(null)
  //     props.onLogout()
  // }

  return (
    <div className="kt-aside kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop" id="kt_aside">  
      <div className="kt-aside__brand kt-grid__item " id="kt_aside_brand">
        <div className="kt-aside__brand-logo">
          <NavLink disabled={props.disabled} to="/">
            <img id="asideBrandLogo" alt="Logo" src={BannerLogoLight} height="22"/>
          </NavLink>
        </div>
        <div className="kt-aside__brand-tools">
          <button
            onClick={() => toggleTitle()}
            className="kt-aside__brand-aside-toggler"
            id="kt_aside_toggler">    
            <span>
              <svg className="nav-movment" width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><path d="M13.706 9.698a.988.988 0 0 0 0-1.407 1.01 1.01 0 0 0-1.419 0l-2.965 2.94a1.09 1.09 0 0 0 0 1.548l2.955 2.93a1.01 1.01 0 0 0 1.42 0 .988.988 0 0 0 0-1.407l-2.318-2.297 2.327-2.307z" fill="currentColor" fillRule="evenodd"></path></svg>
            </span>
            <span>
              <svg className="nav-movment margin-l65" width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><path d="M10.294 9.698a.988.988 0 0 1 0-1.407 1.01 1.01 0 0 1 1.419 0l2.965 2.94a1.09 1.09 0 0 1 0 1.548l-2.955 2.93a1.01 1.01 0 0 1-1.42 0 .988.988 0 0 1 0-1.407l2.318-2.297-2.327-2.307z" fill="currentColor" fillRule="evenodd"></path></svg>
            </span>
          </button>
        </div>
      </div>
      <div className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid" id="kt_aside_menu_wrapper">
        <div style={{ marginTop: '0px' }} id="kt_aside_menu" className="kt-aside-menu " data-ktmenu-vertical="1" data-ktmenu-scroll="1" data-ktmenu-dropdown-timeout="500">
          <ul style={{ paddingTop: '0px' }} className="kt-menu__nav ">
            <li id="menuDashboard" className="kt-menu__item  kt-menu__item--active" aria-haspopup="true">         
              <NavLink disabled={props.disabled} to="/dashboard" className="kt-menu__link ">             
                <span className="kt-menu__link-icon">
                  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon" data-toggle="kt-tooltip" data-placement="right">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <path d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z" fill="#000000" fillRule="nonzero"/>
                      <path d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z" fill="#000000" opacity="0.3"/>
                    </g>
                  </svg>
                </span>
                <span className="kt-menu__link-text">Dashboard</span>
              </NavLink>
            </li>
            <li id="menuPolicy" className="kt-menu__item kt-menu__item--active" aria-haspopup="true">          
              <NavLink disabled={props.disabled} to="/policy-stats/all" className="kt-menu__link ">
                <span data-original-title="Policy Stats" className="kt-menu__link-icon">
                  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon" data-toggle="kt-tooltip" data-placement="right">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <rect x="0" y="0" width="24" height="24"></rect>
                      <rect fill="#000000" opacity="0.3" x="13" y="4" width="3" height="16" rx="1.5"></rect>
                      <rect fill="#000000" x="8" y="9" width="3" height="11" rx="1.5"></rect>
                      <rect fill="#000000" x="18" y="11" width="3" height="9" rx="1.5"></rect>
                      <rect fill="#000000" x="3" y="13" width="3" height="7" rx="1.5"></rect>
                    </g>
                  </svg>
                </span>
                <span className="kt-menu__link-text">Policy Status</span>
              </NavLink>
            </li>
            <li id="menuClients" className="kt-menu__item" aria-haspopup="true">
              <NavLink disabled={props.disabled} to="/clients/all" className="kt-menu__link ">
                <span className="kt-menu__link-icon">
                  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon" data-toggle="kt-tooltip" data-placement="right">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3"/>
                      <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero"/>
                    </g>
                  </svg>
                </span>
                <span className="kt-menu__link-text">Clients</span>
              </NavLink>
            </li>
            {user.categoryId !== 3 &&
              <li id="menuTeam" className="kt-menu__item" aria-haspopup="true">
                <NavLink disabled={props.disabled} to="/team/all" className="kt-menu__link ">
                  <span className="kt-menu__link-icon">
                    <i className="fa fa-users" style={{color: "white"}}></i>            
                  </span>
                  <span className="kt-menu__link-text">Team</span>
                </NavLink>
              </li>
            }
            <li id="menuSubAgency" className="kt-menu__item" aria-haspopup="true">
              <NavLink disabled={props.disabled} to="/sub-agency/policy-status" className="kt-menu__link ">
                <span className="kt-menu__link-icon">
                  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon" data-toggle="kt-tooltip" data-placement="right">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <rect x="0" y="0" width="24" height="24" />
                      <path d="M16.5428932,17.4571068 L11,11.9142136 L11,4 C11,3.44771525 11.4477153,3 12,3 C12.5522847,3 13,3.44771525 13,4 L13,11.0857864 L17.9571068,16.0428932 L20.1464466,13.8535534 C20.3417088,13.6582912 20.6582912,13.6582912 20.8535534,13.8535534 C20.9473216,13.9473216 21,14.0744985 21,14.2071068 L21,19.5 C21,19.7761424 20.7761424,20 20.5,20 L15.2071068,20 C14.9309644,20 14.7071068,19.7761424 14.7071068,19.5 C14.7071068,19.3673918 14.7597852,19.2402148 14.8535534,19.1464466 L16.5428932,17.4571068 Z" fill="#000000" fillRule="nonzero" />
                      <path d="M7.24478854,17.1447885 L9.2464466,19.1464466 C9.34021479,19.2402148 9.39289321,19.3673918 9.39289321,19.5 C9.39289321,19.7761424 9.16903559,20 8.89289321,20 L3.52893218,20 C3.25278981,20 3.02893218,19.7761424 3.02893218,19.5 L3.02893218,14.136039 C3.02893218,14.0034307 3.0816106,13.8762538 3.17537879,13.7824856 C3.37064094,13.5872234 3.68722343,13.5872234 3.88248557,13.7824856 L5.82567301,15.725673 L8.85405776,13.1631936 L10.1459422,14.6899662 L7.24478854,17.1447885 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                    </g>
                  </svg>
                </span>
                <span className="kt-menu__link-text">Sub Agency</span>
              </NavLink>
            </li>
            <li id="menuTask" className="kt-menu__item" aria-haspopup="true">        
              <NavLink disabled={props.disabled} to="/tasks/all" className="kt-menu__link ">
                <span className="kt-menu__link-icon">
                  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon" data-toggle="kt-tooltip" data-placement="right"> 
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <rect x="0" y="0" width="24" height="24" />
                      <path d="M7.14319965,19.3575259 C7.67122143,19.7615175 8.25104409,20.1012165 8.87097532,20.3649307 L7.89205065,22.0604779 C7.61590828,22.5387706 7.00431787,22.7026457 6.52602525,22.4265033 C6.04773263,22.150361 5.88385747,21.5387706 6.15999985,21.0604779 L7.14319965,19.3575259 Z M15.1367085,20.3616573 C15.756345,20.0972995 16.3358198,19.7569961 16.8634386,19.3524415 L17.8320512,21.0301278 C18.1081936,21.5084204 17.9443184,22.1200108 17.4660258,22.3961532 C16.9877332,22.6722956 16.3761428,22.5084204 16.1000004,22.0301278 L15.1367085,20.3616573 Z" fill="#000000"/>
                      <path d="M12,21 C7.581722,21 4,17.418278 4,13 C4,8.581722 7.581722,5 12,5 C16.418278,5 20,8.581722 20,13 C20,17.418278 16.418278,21 12,21 Z M19.068812,3.25407593 L20.8181344,5.00339833 C21.4039208,5.58918477 21.4039208,6.53893224 20.8181344,7.12471868 C20.2323479,7.71050512 19.2826005,7.71050512 18.696814,7.12471868 L16.9474916,5.37539627 C16.3617052,4.78960984 16.3617052,3.83986237 16.9474916,3.25407593 C17.5332781,2.66828949 18.4830255,2.66828949 19.068812,3.25407593 Z M5.29862906,2.88207799 C5.8844155,2.29629155 6.83416297,2.29629155 7.41994941,2.88207799 C8.00573585,3.46786443 8.00573585,4.4176119 7.41994941,5.00339833 L5.29862906,7.12471868 C4.71284263,7.71050512 3.76309516,7.71050512 3.17730872,7.12471868 C2.59152228,6.53893224 2.59152228,5.58918477 3.17730872,5.00339833 L5.29862906,2.88207799 Z" fill="#000000" opacity="0.3"/>
                      <path d="M11.9630156,7.5 L12.0475062,7.5 C12.3043819,7.5 12.5194647,7.69464724 12.5450248,7.95024814 L13,12.5 L16.2480695,14.3560397 C16.403857,14.4450611 16.5,14.6107328 16.5,14.7901613 L16.5,15 C16.5,15.2109164 16.3290185,15.3818979 16.1181021,15.3818979 C16.0841582,15.3818979 16.0503659,15.3773725 16.0176181,15.3684413 L11.3986612,14.1087258 C11.1672824,14.0456225 11.0132986,13.8271186 11.0316926,13.5879956 L11.4644883,7.96165175 C11.4845267,7.70115317 11.7017474,7.5 11.9630156,7.5 Z" fill="#000000"/>
                    </g>
                  </svg>
                </span>
                <span className="kt-menu__link-text">Tasks</span>
              </NavLink>
            </li>
            <li id="menuFiles" className="kt-menu__item" aria-haspopup="true">
              <NavLink disabled={props.disabled} to="/files/public" className="kt-menu__link ">
                <span className="kt-menu__link-icon">
                  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon" data-toggle="kt-tooltip" data-placement="right">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fillRule="nonzero" opacity="0.3"/>
                      <rect fill="#000000" x="6" y="11" width="9" height="2" rx="1"/>
                      <rect fill="#000000" x="6" y="15" width="5" height="2" rx="1"/>
                    </g>
                  </svg>
                </span>
                <span className="kt-menu__link-text">Files</span>
              </NavLink>
            </li>
            {user.categoryId !== 3 &&
              <li id="menuReports" className="kt-menu__item" aria-haspopup="true">
                <NavLink disabled={props.disabled} to="/reports/businessReports" className="kt-menu__link ">
                  <span className="kt-menu__link-icon">
                    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon" data-toggle="kt-tooltip" data-placement="right">
                      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <rect x="0" y="0" width="24" height="24" />
                        <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3"/>
                        <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000"/>
                        <rect fill="#000000" opacity="0.3" x="10" y="9" width="7" height="2" rx="1"/>
                        <rect fill="#000000" opacity="0.3" x="7" y="9" width="2" height="2" rx="1"/>
                        <rect fill="#000000" opacity="0.3" x="7" y="13" width="2" height="2" rx="1"/>
                        <rect fill="#000000" opacity="0.3" x="10" y="13" width="7" height="2" rx="1"/>
                        <rect fill="#000000" opacity="0.3" x="7" y="17" width="2" height="2" rx="1"/>
                        <rect fill="#000000" opacity="0.3" x="10" y="17" width="7" height="2" rx="1"/>
                      </g>
                    </svg>
                  </span>
                  <span className="kt-menu__link-text">Reports</span>
                </NavLink>
              </li>
            }      
            <li id="menuTool" className="kt-menu__item" aria-haspopup="true">
              <NavLink disabled={props.disabled} to="/tools" className="kt-menu__link ">
                <span className="kt-menu__link-icon">
                  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon" data-toggle="kt-tooltip" data-placement="right">
                      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                          <rect x="0" y="0" width="24" height="24"/>
                          <path d="M15.9497475,3.80761184 L13.0246125,6.73274681 C12.2435639,7.51379539 12.2435639,8.78012535 13.0246125,9.56117394 L14.4388261,10.9753875 C15.2198746,11.7564361 16.4862046,11.7564361 17.2672532,10.9753875 L20.1923882,8.05025253 C20.7341101,10.0447871 20.2295941,12.2556873 18.674559,13.8107223 C16.8453326,15.6399488 14.1085592,16.0155296 11.8839934,14.9444337 L6.75735931,20.0710678 C5.97631073,20.8521164 4.70998077,20.8521164 3.92893219,20.0710678 C3.1478836,19.2900192 3.1478836,18.0236893 3.92893219,17.2426407 L9.05556629,12.1160066 C7.98447038,9.89144078 8.36005124,7.15466739 10.1892777,5.32544095 C11.7443127,3.77040588 13.9552129,3.26588995 15.9497475,3.80761184 Z" fill="#000000"/>
                          <path d="M16.6568542,5.92893219 L18.0710678,7.34314575 C18.4615921,7.73367004 18.4615921,8.36683502 18.0710678,8.75735931 L16.6913928,10.1370344 C16.3008685,10.5275587 15.6677035,10.5275587 15.2771792,10.1370344 L13.8629656,8.7228208 C13.4724413,8.33229651 13.4724413,7.69913153 13.8629656,7.30860724 L15.2426407,5.92893219 C15.633165,5.5384079 16.26633,5.5384079 16.6568542,5.92893219 Z" fill="#000000" opacity="0.3"/>
                      </g>
                  </svg>
                </span>
                <span className="kt-menu__link-text">Tools</span>
              </NavLink>
            </li>      
          </ul>
        </div>
      </div>

      <div className="flex-column-auto" id="kt_aside_footer">
        <div style={{ marginTop: '0px' }} className="kt-aside-menu " data-ktmenu-vertical="1" data-ktmenu-scroll="1" data-ktmenu-dropdown-timeout="500">
          <ul style={{ paddingTop: '0px' }} className="kt-menu__nav ">
            <li id="menuSupport" className="kt-menu__item" aria-haspopup="true">
                  <NavLink disabled={props.disabled} to="/report" className="kt-menu__link ">
                    <span className="kt-menu__link-icon">
                      <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon" data-toggle="kt-tooltip" data-placement="right">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <rect x="0" y="0" width="24" height="24"/>
                            <path d="M16,15.6315789 L16,12 C16,10.3431458 14.6568542,9 13,9 L6.16183229,9 L6.16183229,5.52631579 C6.16183229,4.13107011 7.29290239,3 8.68814808,3 L20.4776218,3 C21.8728674,3 23.0039375,4.13107011 23.0039375,5.52631579 L23.0039375,13.1052632 L23.0206157,17.786793 C23.0215995,18.0629336 22.7985408,18.2875874 22.5224001,18.2885711 C22.3891754,18.2890457 22.2612702,18.2363324 22.1670655,18.1421277 L19.6565168,15.6315789 L16,15.6315789 Z" fill="#000000"/>
                            <path d="M1.98505595,18 L1.98505595,13 C1.98505595,11.8954305 2.88048645,11 3.98505595,11 L11.9850559,11 C13.0896254,11 13.9850559,11.8954305 13.9850559,13 L13.9850559,18 C13.9850559,19.1045695 13.0896254,20 11.9850559,20 L4.10078614,20 L2.85693427,21.1905292 C2.65744295,21.3814685 2.34093638,21.3745358 2.14999706,21.1750444 C2.06092565,21.0819836 2.01120804,20.958136 2.01120804,20.8293182 L2.01120804,18.32426 C1.99400175,18.2187196 1.98505595,18.1104045 1.98505595,18 Z M6.5,14 C6.22385763,14 6,14.2238576 6,14.5 C6,14.7761424 6.22385763,15 6.5,15 L11.5,15 C11.7761424,15 12,14.7761424 12,14.5 C12,14.2238576 11.7761424,14 11.5,14 L6.5,14 Z M9.5,16 C9.22385763,16 9,16.2238576 9,16.5 C9,16.7761424 9.22385763,17 9.5,17 L11.5,17 C11.7761424,17 12,16.7761424 12,16.5 C12,16.2238576 11.7761424,16 11.5,16 L9.5,16 Z" fill="#000000" opacity="0.3"/>
                        </g>
                      </svg>
                    </span>
                    <span className="kt-menu__link-text">Support</span>
                  </NavLink>
                </li>      
            </ul>

          </div>
       
      </div>
    </div>
  );
};

export default sideMenu;
